import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../../../Layout"
import Navbar from "../../../Navbar.js"
import Footer from "../../../Footer.js"
import { Container, Row, Col } from "react-bootstrap"
import JobIcon from "../../../../../static/svg/professional-icon.svg"
import Pagination from "../../../Pagination"
import {
  Heading24,
  Heading48,
  Paragraph16,
} from "../../../../styles/PageStyles.js"

const ProfessionalPageId = ({ data, pageContext, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  const careers = data?.allMarkdownRemark?.nodes
  const { numPages } = pageContext

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout>
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid>
          <div className="heading">
            <Heading48>Berpengalaman</Heading48>
          </div>
          <Row lg={3} md={1} xs={1}>
            {careers.length > 0 ? (
              <>
                {careers?.map((career, index) => (
                  <Col className="job-position-column" key={index}>
                    <JobPositionCard>
                      <JobIcon className="icon" />
                      <Paragraph16 className="date">
                        {career?.frontmatter?.date}
                      </Paragraph16>
                      <Paragraph16 className="title">
                        {career?.frontmatter?.title}
                      </Paragraph16>
                      <Paragraph16 className="desc-title">
                        Job Specialization:
                      </Paragraph16>
                      <Paragraph16 className="desc">
                        {career?.frontmatter?.jobSpecialization?.map(
                          (specialization, index) => (
                            <span key={index}>{specialization}</span>
                          )
                        )}
                      </Paragraph16>
                      <Paragraph16 className="desc-title">
                        Job Type:
                      </Paragraph16>
                      <Paragraph16 className="desc">
                        {career?.frontmatter?.jobType}
                      </Paragraph16>
                      <Paragraph16 className="desc-title">
                        Career Level:
                      </Paragraph16>
                      <Paragraph16 className="desc">
                        {career?.frontmatter?.careerLevel}
                      </Paragraph16>
                      <Link className="link-btn" to={career?.fields?.slug}>
                        Selengkapnya
                      </Link>
                    </JobPositionCard>
                  </Col>
                ))}
              </>
            ) : (
              <Heading24>No careers found.</Heading24>
            )}
          </Row>
          <Pagination
            numPages={numPages}
            basePath="careers"
            slug="professional"
          />
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default ProfessionalPageId

const StyledContainer = styled(Container)`
  padding: 150px 10% 100px 10%;
  min-height: 80vh;

  .heading {
    h1 {
      font-weight: bold;
    }
    margin-bottom: 75px;
  }

  .job-position-column {
    padding: 0 25px;
  }

  @media only screen and (max-width: 1025px) {
    .job-position-column {
      width: 50%;
    }
  }

  @media only screen and (max-width: 769px) {
    .job-position-column {
      width: 100%;
    }
  }

  @media only screen and (max-width: 426px) {
    padding: 150px 5% 50px 5%;
  }
`

const JobPositionCard = styled.div`
  min-height: 360px;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px 25px;
  margin-bottom: 70px;
  position: relative;

  .icon {
    position: absolute;
    top: -10%;
  }

  .date {
    font-weight: bold;
    text-align: right;
    color: #139adf;
    margin-bottom: 30px;

    @media only screen and (max-width: 850px) {
      font-size: 14px;
    }
  }

  .title {
    min-height: 40px;
    font-weight: bold;
    color: #0073af;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .desc-title,
  .desc {
    color: #5b5d5e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      &:after {
        content: ", ";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }

  .desc-title {
    font-weight: 500;
  }

  .desc {
    min-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .link-btn {
    display: table;
    background: #0073af;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 25px auto 0 auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #ffffff;

    @media only screen and (max-width: 769px) {
      font-size: 16px;
      line-height: 20px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 769px) {
    .icon {
      width: 60px;
    }
  }
`

export const query = graphql`
  query professionalPageQueryId($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(id)/(careers-detailed)/(professional)/" }
        frontmatter: { templateKey: { eq: "careers-detailed" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        html
        fields {
          slug
        }
        frontmatter {
          title
          careerLevel
          jobType
          jobSpecialization
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
